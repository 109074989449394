import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ReactHtmlParser from "react-html-parser";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Loader from "../components/Reusables/Loader";
import SummaryBlock from "../components/Home/SummaryBlock";
import DescriptionBlock from "../components/Home/DescriptionBlock"

const StyledWrapper = styled.div`
  margin-top: 100px;

  @media (max-width: 600px) {
    margin-top: 50px;
  }

  .privacy-sub-title {
    font-size: 22px;
    font-weight: bold;
    @media (max-width: 600px) {
      font-size: 18px;
    }
  }

  .li-subhead {
    font-size: 20px;
    font-weight: bold;
    @media (max-width: 600px) {
      font-size: 18px;
    }
  }

  .react-desc a {
    color: black;
    text-decoration: underline;
  }
  .text-2 {
    margin-top: 5rem;
  }
  .privacy-text .subtext{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
  }
`;
const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Privacy />
    </QueryClientProvider>
  );
}
const fetchData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/privacy-page`);
};

const Privacy = () => {
  const [newData, setNewData] = useState(null);
  const { data: pageData } = useQuery("privacyData", fetchData);
  useEffect(() => {
    if (pageData !== undefined) {
      setNewData(pageData.data);
    }
  }, [pageData]);
  return (
    <Layout>
      <StyledWrapper>
        {newData === null && <Loader />}
        {/* <SEO title="Privacy Notice & Use of Cookies – upReach" description={newData.SEOdescription} /> */}
        {newData && <SEO title={newData.SEOtitle} description={newData.SEOdescription} />}
        <div className="container">
          {newData && <h1 className="text-center mb-3">{newData.Title}</h1>}
          <ol>
            {newData &&
              newData.policyComp.length > 0 &&
              newData.policyComp.map(item => (
                <div>
                  <li className="privacy-sub-title"> {item.title}</li>
                  {/* <ReactMarkdown source={item.subText} className="react-desc" /> */}
                  <div className="privacy-text">
                  <DescriptionBlock description={item.subText}  />
                  </div>
                </div>
              ))}
          </ol>
          {newData && (
            <h1 className="text-center text-2 mb-3">{newData.Title2}</h1>
          )}

          {newData &&
            newData.GlossaryOfTerms.length > 0 &&
            newData.GlossaryOfTerms.map(item => (
              <div>
                <p className="privacy-sub-title"> {item.title}</p>
                {/* <ReactMarkdown source={item.subText} className="react-desc" /> */}
                <div className="privacy-text">
                <DescriptionBlock description={item.subText}  />
                </div>
              </div>
            ))}
        </div>
      </StyledWrapper>
    </Layout>
  );
};
